export default {
  notReady: 'notReady',
  waitForStart: 'waitForStart',
  cantStartProject: 'cantStartProject',
  mustStartProject: 'mustStartProject',
  canStartProjectBlueReady: 'canStartProjectBlueReady',
  resourcesSelection: 'resourcesSelection',
  waitForResourcesCapacity: 'waitForResourcesCapacity',
  canStartProjectBlueOverCapacity: 'canStartProjectBlueOverCapacity',
  capacityReceived: 'capacityReceived',
  mustChangeEndDate: 'mustChangeEndDate',
  waitToStartNewWeek: 'waitToStartNewWeek',
  projectFinished: 'projectFinished',
  needToSelectCriticalResource: 'needToSelectCriticalResource',
}
