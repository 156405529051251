export default {
  general: {
    name: 'Project Flow Games',
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    update: 'Update',
    close: 'Close',
    cancel: 'Cancel',
    create: 'Create',
    remove: 'Delete',
    confirmation: 'Confirmation',
    search: 'Search',
    language: 'Language',
    validate: 'Validate',
    connect: 'Connect',
    disconnect: 'Disconnection',
    welcome: 'Welcome {name}',
    loading: 'Load in progress... Please wait',
    loadingShort: 'Loading...',
    empty: 'Empty',
    actions: 'Actions',
    start: 'Start',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
  },
  date: {
    date: 'dd/MM/yyyy',
    time: 'HH:mm',
    dateAndTime: 'dd/MM/yyyy à HH:mm',
  },
  language: {
    french: 'French',
    english: 'English',
  },
  menu: {},
  title: {
    login: 'Project Flow Game',
    home: 'Home',
  },
  color: {
    red: 'Red',
    pink: 'Pink',
    purple: 'Purple',
    'deep-purple': 'Deep Purple',
    indigo: 'Indigo',
    blue: 'Blue',
    'light-blue': 'Light blue',
    cyan: 'Cyan',
    teal: 'Teal',
    green: 'Green',
    'light-green': 'Light green',
    lime: 'Lime',
    yellow: 'Yellow',
    amber: 'Amber',
    orange: 'Orange',
    'deep-orange': 'Deep Orange',
    brown: 'Brown',
    'blue-grey': 'Blue grey',
    grey: 'Grey',
  },
  error: {
    form: {
      required: 'This field is required',
      minLength: 'This field must contain at least {length} characters',
      maxLength: 'This field can not exceed more than {length} characters',
      exactLength: 'The field must contain exactly {length} characters',
      noSpaces: 'The field can not have a space',
      noEqualPassword: 'Passwords are not the same',
      userExist: 'User name already exists in your organization',
      alpha: 'The field can only contain letters',
      alphanumeric: 'The field can only contain letters and figures',
      greater: 'The number must be greater than {number}',
      lower: 'The number must be lower than {number}',
      email: 'The email is not valid',
    },
    api: {
      TrainingSessionClosedError: 'Impossible to connect. The session is closed.',
      TrainingSessionNotFoundError: 'Impossible to connect. The session does not exist.',
      TrainingSessionNotOpenError: 'Impossible to connect. The session is not yet opened.',
      TrainingSessionPlayerCodeNotFoundError: 'Impossible to connect. The player code does not exist',
      'Not Found': 'Unknown error occured. (404)',
      UnknownError: 'Unknown error occured.',
    },
  },
  login: {
    fields: {
      sessionCode: 'Session code',
      playerCode: 'User code',
      playerName: 'Full name',
      playerEmail: 'E-mail',
    },
    formrules: {
      playerCodeFormat: 'The field must be something like A00',
    },
    message: {
      sessionClosed: 'You have been disconnected because the session has been closed.',
      logoutConfirm: 'Do you really want to leave the game ?',
    },
  },
  home: {
    title: 'Session : {sessionCode} - User: {playerName}',
    game: {
      title: {
        multiProjectStandard: 'Multi-projects standard',
        multiProjectCcpm: 'Multi-projects CCPM',
        multiTaskParallel: 'Multitask',
        multiTaskSequential: 'Monotask',
        networkDiagramWithoutInstructions: 'Project Plan without instruction',
        networkDiagramWithInstructions: 'Project Plan with instruction',
        onTime: 'Time Estimation',
        criticalChain: 'Critical Chain',
      },
      status: {
        closed: 'Closed',
        opened: 'Opened',
        finished: 'Finished',
      },
    },
  },
  notification: {
    pause: 'Games are in pause',
    play: 'Games are not paused anymore',
    gameOpened: 'A new game has been unlocked',
    gameFinished: 'A game has just finished',
    socket: {
      disconnect: 'The connexion to the host has been lost',
      reconnect: 'The connexion to the host is restored',
    },
  },
  multiproject: {
    common: {
      managementProjectTitle: 'Management of {projectName}',
      managementStartedProjectTitle: 'Management of the project started in week {week}',
      weekStartReal: 'Start of the project',
      weekEndPlanned: 'Forecasted delivery date of the project',
      week: 'Week',
      currentWeek: 'Week in progress : {week}',
      weekEndPlannedChanged: 'The delivery date of the project has been updated',
    },
    welcome: {
      title: 'Welcome',
      projectNameText: 'You are now the project manager of {projectName}',
      projectStartText: 'The start date is planed in week {weekStartPlanned}',
      readyButton: 'I am ready',
      waitingOthers: 'Wait for others players {readyPlayers}/{totalPlayers}',
      error: 'An error occured while ',
    },
    start: {
      title: {
        gantt: 'Gantt',
        startPlanned: 'Start of the project in week {week}',
      },
      cantStartProject: {
        title: 'You can not start your project right now !',
        subtitle: 'Please wait',
        icon: 'mdi-close-octagon',
        color: 'blue-grey',
      },
      canStartProjectBlueReady: {
        title: 'The "{resource}" resource is available !',
        subtitle: 'Do you want to start your project now ?',
        icon: 'mdi-information-outline',
        color: 'info',
      },
      startProject: 'Start the project',
      doesntStartProject: 'Refuse',
      ccpm: {
        title: 'Release the project',
        mustStartProject: {
          title: 'The critical resource is available !',
          subtitle: 'Now is the time to launch your project.',
          icon: 'mdi-information-outline',
          color: 'success',
        },
        cantStartProject: {
          title: 'You can not start your project right now !',
          subtitle: 'Please wait...',
          icon: 'mdi-close-octagon',
          color: 'blue-grey',
        },
      },
    },
    resourcesSelection: {
      title: 'Claim for resources',
      confirmButton: 'Validate resources',
      errorTitle: 'Mistakes when you claimed for your resources',
      fixSelectionButton: 'Update my selection',
      resourceMissing: 'You forgot to claim a resource. Please claim all necessary resources',
      resourceUnnecessary: 'You claimed for unnecessary resources. Please only claim for necessary resources',
    },
    resourcesWait: {
      title: 'Receive the capacity of resources',
      message: 'Please wait for all project managers claimed for their resources.',
    },
    blueOverCapacity: {
      title: 'There are {capacity} of capacity left for the resource "{resource}" this week',
      subtitle: 'Do you want to use it et start your project ?',
      icon: 'mdi-help',
      color: 'info',
      acceptBlueOverCapacity: 'Start the project',
      declineBlueOverCapacity: 'Refuse',
    },
    capacityReceived: {
      title: 'Receive the capacity of resources',
      button: 'Update the Gantt',
      noCapacity: 'No available capacity this week.',
      message: {
        firstPart: 'The "{name}" resource moves on ',
        lastPart: ' on his task',
      },
      lastCapacity: 'Capacity',
      lastNumberOfRequests: 'Demands',
      lastCapacityDetail: 'Resource capacity available for all projects during the week',
      lastNumberOfRequestsDetail: 'Number of projects needing the resource this week',
    },
    mustChangeEndDate: {
      title: 'Update the delivery date',
      message: 'You must update your delivery date !',
    },
    waitNewWeek: {
      title: 'Start a new week',
      message: 'Please wait for others project manager to update their information',
    },
    projectFinished: {
      done: '| Finished in {duration} week | Finished in {duration} weeks',
      changes: 'Without change in the delivery date. | With {changes} update in delivery date. | with {changes} updates of the delivery date.',
      message: 'Your project is finished !',
    },
    waitingCriticalResource: {
      title: 'Before you start, please choose the critical resource with the trainer.',
    },
    feverchart: {
      title: 'Fever Chart Multi-projects',
      progress: 'Progress',
      buffer: 'Buffer',
      mono: 'Mono',
      multi: 'Multi',
    },
    automatic: {
      message: 'Your project is on auto mode. You can not access to it right now.',
    },
  },
  multitask: {
    welcome: {
      multiTaskParallel: {
        explain: 'In this exercise, you have to make combinations of letters, numbers and shapes. The order of selection of the shapes is: square, circle, triangle. You must obtain a sequence of values like this: A 1 square, B 2 circle, C 3 triangle, etc...',
        videoInstructionUrl: '/videos/instruction-multitask-fr.mp4',
      },
      multiTaskSequential: {
        explain: 'In this exercise, you have to make combinations of letters, numbers and shapes. The order of selection of the shapes is: square, circle, triangle. However, this time you will start by selecting all the letters: A, B, C... then all the numbers: 1, 2, 3... then all the shapes: square, circle, triangle...',
        videoInstructionUrl: '/videos/instruction-monotask-fr.mp4',
      },
      title: 'Welcome',
      confirmMessage: 'Did you understand the rules of the game ? Once it is ok, the game will start !',
      startGame: 'Start the game',
    },
    playing: {
      multiTaskParallel: {
        informations: 'You must select the first letter, then the figure and the geometrical form in the correct order (Square, Circle, Triangle)',
      },
      multiTaskSequential: {
        informations: 'You must select ALL letters, then ALL figures and the geometrical forms in the correct order (Square, Circle, Triangle)',
      },
      countdown: '| The game starts in {countdown} second. | The game starts in {countdown} seconds',
      mistakeMessage: 'You made a mistake',
      choiceList: 'List of input to select',
      resultList: 'Results',
      finished: 'You have finished, please wait...',
    },
    finished: {
      timePlayed: '| You finished in {timePlayed} second | You finished in {timePlayed} seconds',
      numberOfMistakes: 'with no mistake. | with {numberOfMistakes} mistake | with {numberOfMistakes} mistakes',
    },
  },
  networkdiagram: {
    welcome: {
      networkDiagramWithoutInstructions: {
        explain: 'In this exercise, you must build a house based on the numbered geometrical forms. To do this, you must move the forms to build until you build the house.',
        videoInstructionUrl: '/videos/networkDiagram-without-instructions.mp4',
      },
      networkDiagramWithInstructions: {
        explain: 'In this exercise, you must build a house based on the numbered geometrical forms. To do this, you must move the forms to build until you build the house. However, this time, you must apply the necessary instructions to build the house. You must realise each instruction, then click to the next one, until the last one to finsh the house.',
        videoInstructionUrl: '/videos/networkDiagram-with-instructions.mp4',
      },
      title: 'Welcome',
      confirmMessage: 'Do you understand the rules ? Once validated, the game will start !',
      startGame: 'Start of the game',
    },
    playing: {
      nextInstruction: 'Next instruction',
      validateConfirm: 'Do you want to finish the game and send your result to the trainer ?',
    },
    finished: {
      noPlayed: 'The game is finished but you have not start the game',
      timePlayed: 'Finished in\n{seconds} seconds | Finished in\n{minutes} minute and {seconds} seconds | Finished in\n{minutes} minutes and {seconds} seconds',
    },
  },
  ontime: {
    waitNextQuestionButton: 'Answer sent',
    waitNextQuestionMessage: 'Your answer has been sent. Please wait...',
    gameFinishedMessage: 'You answered to all questions!',
    selectLabel: 'Your answer',
    dayBefore: 'The day before',
    choiceList: {
      '00:00': '00:00',
      '00:30': '00:30 am',
      '01:00': '01:00 am',
      '01:30': '01:30 am',
      '02:00': '02:00 am',
      '02:30': '02:30 am',
      '03:00': '03:00 am',
      '03:30': '03:30 am',
      '04:00': '04:00 am',
      '04:30': '04:30 am',
      '05:00': '05:00 am',
      '05:30': '05:30 am',
      '06:00': '06:00 am',
      '06:30': '06:30 am',
      '07:00': '07:00 am',
      '07:30': '07:30 am',
      '08:00': '08:00 am',
      '08:30': '08:30 am',
      '09:00': '09:00 am',
      '09:30': '09:30 am',
    },
  },
  criticalchain: {
    bufferNotDefined: 'To be define',
    welcome: {
      explain: 'In this exercise, you wil start with a Gantt. Firstly you will have to eliminate multitasking, then define the critical path, and finally size your buffer.',
      title: 'Welcome',
      confirmMessage: 'Do you understand the rules ? Once validated, the exercice will start !',
      startGame: 'Start the game',
    },
    steps: {
      CriticalChainRemoveMultitask: 'Multi-task',
      CriticalChainSelectCriticalChain: 'Critical Chain',
      CriticalChainDefineBufferSize: 'Buffer',
    },
    removeMultitask: {
      message: 'Move the tasks of the gantt until resources do not have 2 things to do at the same time.',
    },
    selectCriticalChain: {
      message: 'Select the tasks which belongs to the critical chain on the Gantt chart.',
    },
    defineBufferSize: {
      message: 'Size the buffer (in days) for this project.',
      bufferSuffix: 'Days',
    },
    finished: {
      message: 'You have finished !',
    },
  },
}
